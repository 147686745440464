.grid {
  margin: 10px auto;
  display: grid;
  grid-gap: 30px;
  padding: 25px;
  grid-template-columns: repeat(3, 1fr) !important;
  /* justify-content: center; */
}
.button_flex {
  display: flex;
  justify-content: space-between;
}
ul {
  list-style: none;
  word-break: break-all;
}
.vehicle_list_data {
  display: flex;
  gap: 5px;
}
.feature_content {
  height: 180px;
  overflow: hidden;
  width: 100%;
}
.check_button {
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #005a2b;
  margin-top: 0px;
}
.engine_year {
  display: flex;
  justify-content: space-between;
  /* gap: 40%; */
}
.amount {
  margin-bottom: 0;
}
.mobile_responsive_engine {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-weight: 700;
  margin-left: -5px;
  cursor: pointer;
}
.mobile_responsive_detail {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
}
.engine_year_font {
  font-size: 12px;
  font-weight: 900;
}
.read_more_button {
  cursor: pointer;
  font-size: 12px;
}
.read_more_button:hover {
  color: #005a2b;
  text-decoration: underline;
}
.show_icon {
  margin-bottom: 4px;
  color: #005a2b;
}
.detail_button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  vertical-align: middle;
  width: 13rem !important;
  height: 3rem !important;
}
ul {
  text-align: start;
  padding: 0;
}
.info_button {
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}
.list_data {
  font-family: var(--chakra-fonts-secondary);
  font-size: 12px;
  line-height: 0.875rem;
  letter-spacing: 0.01rem;
  color: var(--chakra-colors-darkCoolGrey);
}
.card_heading {
  display: flex;
  justify-content: space-between;
  width: 87%;
  margin: 0 auto;
  margin-bottom: 30px;
  align-items: center;
}
.sort_by_modal {
  display: flex;
  gap: 20px;
  font-size: 12px;
}
.sort_by_label {
  margin-top: 10px;
  font-size: 12px;
}
.select-box {
  border-bottom: none;
}
.dropdown_list {
  min-width: 150px;
  border-color: black;
}
.css-13cymwt-control {
  border-color: black !important;
}
.back_arrow {
  display: flex;
  gap: 10px;
  cursor: pointer;
  margin-left: -8px;
}
.no_data {
  display: flex;
  justify-content: center;
}
.horizantol_line {
  width: 100%;
}
.order_process {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}
.order_process:hover {
  text-decoration: underline;
}
@media only screen and (min-width: 1300px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 1400px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 1900px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 600px) {
  .card_image {
    object-fit: unset !important;
    max-height: 400px;
  }
  .mobile_responsive {
    display: flex;
  }
  .amount-responsive {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1350px) {
  .card_image {
    object-fit: cover !important;
    padding: 15px;
    max-height: 400px;
  }
}
