nav li {
  margin: 0 6px;
  font-size: 9px;
  letter-spacing: 0.1rem;
  text-decoration: none;
  transition: all 0.5s ease;
}
.country_logo {
  width: 20px;
  height: 13px;
  margin: 1px 10px 0 20px;
}
.country_logo_title {
  font-size: 10px;
  font-weight: bolder;
  font-family: "AvenirNextRegular";
}
.footer_menu {
  padding: 3rem;
  background-color: #4a4f54;
}
nav li:hover {
  text-decoration: underline;
  color: #4a4f54;
  cursor: pointer;
}

.list-group {
  display: flex;
  flex-direction: row !important;
  list-style: none;
  padding: 15px 24px;
  justify-content: start;
  gap: 15px;
}
.footer_row {
  width: 100%;
  margin-top: 0;
}
.footer-list-item {
  font-size: 8px;
  letter-spacing: 1px;
  text-decoration: none;
  transition: all 0.5s ease;
  margin-top: 0px;
  margin-left: 25px;
  font-family: "AvenirNextRegular";
  color: #000;
}
.icon-container {
  display: flex;
  align-items: center;
}

.nav-menu-links {
  font-weight: 400;
  color: #e4e8e8;
  font-size: 10px;
  letter-spacing: 0.1rem;
  text-decoration: none;
  margin-bottom: 0.2rem !important;
  font-family: "AvenirNextRegular";
}

.nav-menu-links:hover {
  text-decoration: underline;
  color: #e4e8e8;
}

div a {
  color: #4a4f54;
  font-size: 0.6rem;
  letter-spacing: 0.1em;
  text-decoration: none;
  font-weight: bold;
}

div a:hover {
  text-decoration: underline;
  color: #4a4f54;
}

.social-icon {
  font-size: 1.5rem;
  color: white;
}

.span-logo {
  background-color: #0c121c;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.span-logo-facebook:hover {
  background-color: #4267b2 !important;
}

.span-logo-youtube:hover {
  background-color: #ff0000 !important;
}

.span-logo-twitter:hover {
  background-color: #1da1f2 !important;
}

.span-logo-instagram:hover {
  background-color: #e1306c !important;
}

.bottom-margin {
  margin-bottom: 1rem;
  font-family: "AvenirNextRegular";
}

.image-container {
  background-size: auto;
  background-position: center;

  display: flex;
  margin: 0;

  justify-content: center;
  align-items: center;
  background-position: "center";
  background-repeat: "no-repeat";
  background-size: "cover";
}

.hob-name-image {
  width: 70%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 28rem;
}
.custom-button {
  margin-top: -70px;
  margin-left: 154px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hob-name-image:hover {
  .custome-button {
    background-color: #000;
  }
}

.image-layer {
  background-color: #000;
  opacity: 0.2;
  margin-top: -5px;
  width: 33.33%;
  height: 65vh;
  position: absolute;
}
.policy_container {
  padding: 0 40px;
  color: #4a4f54;
}
/* Media query for small devices (max-width: 600px) */
@media only screen and (max-width: 600px) {
  nav ul {
    display: flex;
    align-items: start;
    padding: 10;
  }

  .list-group {
    flex-direction: column !important;
    padding-left: 1.5rem !important;
    /* gap: 5px; */
  }
  .span-logo {
    height: 35px;
    width: 35px;
  }

  .social-icon {
    font-size: 1rem;
    color: white;
  }

  div a {
    font-size: 0.6rem;
    letter-spacing: 0.1rem;
  }

  .image-container {
    height: 33.33vh;
    flex-direction: column;
  }

  .hob-name-image {
    margin-bottom: 2.5rem;
  }
  .custom-button {
    height: 4%;
    width: 18%;
    margin-left: 145px;
    justify-self: end;
  }
  .image-layer {
    background-color: #000;
    opacity: 0.5;
    margin-top: -5px;
    width: 100%;
    height: 33.33vh;
    position: absolute;
  }
}

@media only screen and (min-width: 700px) and (max-width: 950px) {
  .image-container {
    height: 100%;
    flex-direction: column;
  }
  .image-layer {
    background-color: #000;
    opacity: 0.2;
    margin-top: -5px;
    width: 100%;
    height: 49%;
    position: absolute;
  }
  .custom-button {
    height: 4%;
    width: 18%;
    margin-left: 45%;
  }
}
@media only screen and (min-width: 1900px) and (max-width: 2600px) {
  .image-container {
    width: 100%;
    height: 70vh;
  }
  .image-layer {
    width: 33.33vw;
    height: 70vh;
  }
  .hob-name-image {
    width: 70%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 41rem;
  }
  .custom-button {
    margin-top: -195px;
    margin-left: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1900px) {
  .image-container {
    width: 100%;
    height: 68vh;
  }
  .image-layer {
    width: 33vw;
    height: 68vh;
  }
  .hob-name-image {
    width: 70%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 25rem;
  }
  .custom-button {
    margin-top: -105px;
    margin-left: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.custome-enter-button {
  display: flex;
  position: absolute;
  border: 1px solid gray;
  top: 210px;
  color: white;
}
