@media screen and (min-width: 768px) {
  .links {
    height: auto;
    display: flex;
  }

  li {
    padding: 0 0px;
  }
}

/* added css */
.menu-icon {
  display: none;
}

@media screen and (min-width: 360px) {
  .nav-logo {
    margin-left: 5rem;
    margin-right: 0 !important;
  }
  .main-logo {
    margin-left: 5rem;
    cursor: pointer;
  }
}
@media screen and (min-width: 320px) and (max-width: 360px) {
  .nav-logo-new {
    font-size: 10px;
    cursor: pointer;
  }
  .main-logo {
    margin-left: 5rem;
    cursor: pointer;
  }
}
@media screen and (max-width: 960px) {
  .navbar {
    position: relative;
  }
  .navbar-container-new {
    padding: 0 18px;
  }
  .nav-logo-new {
    font-size: 10px !important;
    font-family: "JaguarExtraBold";
    margin: 0px 0px 0px 60px !important;
    cursor: pointer;
  }
  .main-logo {
    margin-left: 5rem;
    cursor: pointer;
  }

  .nav-menu-new {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    margin-top: 15px;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1000;
  }

  .nav-menu-new.active {
    background: #fff;
    left: -30px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1000000;
    display: block;
    margin-top: 0;
  }

  .nav-links {
    text-align: center;
    padding: 1rem;
    width: 100%;
    display: table;
    color: #0c121c;
  }

  .nav-links:hover {
    background-color: #fff;
    border-radius: 0;
    z-index: 999999;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    left: 50px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #000 !important;
    font-size: 2rem;
  }

  .fa-bars {
    color: #000 !important;
  }
}

/*  */
.navbar-container {
  padding: 2.5rem 2.5rem 2.5rem 0;
  height: 2.5rem;
  box-shadow: 0px 2px 5px #e9ecec;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #fff;
}
.main-logo-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.main-logo {
  margin: 0px 0px 0px 24px;
  cursor: pointer;
}
.nav-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-menu-new {
  display: grid;
  grid-template-columns: repeat(5, auto);
  list-style: none;
  text-align: center;
  justify-content: start;
  margin-top: 15px;
}

.nav-links {
  padding: 0.5rem 0;
  margin: 0;
  color: #0c121c;
}

ul li a {
  font-size: 1.25em;
  line-height: 1.4;
  pointer-events: none !important;
  margin-top: 15px !important;
}

.nav-links:hover {
  background-color: #e9ecec;
  text-decoration: none !important;
  cursor: pointer;
}

.nav-button-group {
  width: 4rem;
}

.nav-buttons {
  padding: 2rem 1rem;
}

.nav-buttons:hover {
  background-color: #e9ecec;
  cursor: pointer;
}

.nav-logo {
  margin-right: 15rem;
  height: 10px;
}

/* Responsive */
.menu-icon {
  color: white;
  cursor: pointer;
  display: none;
}

@media screen and (max-width: 850px) {
  .menu-icon {
    display: block;
    margin-top: -10px;
  }
  .nav-logo-new {
    display: none;
  }
  .main-logo {
    margin-left: 4rem;
    cursor: pointer;
  }
}

.nav-bar {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: var(--bgcolor);
  text-align: center;
}

/*  CSS Added navbar */

.menu-link {
  display: none;
}

/* add navbar*/

/* li {
  padding: 10px 0;
} */

.links {
  height: 0;
  overflow: hidden;
  list-style-type: none;
  font-weight: bold;
}

.show-nav {
  height: 120px;
}
.video-title {
  font-size: 3.5rem;
  position: relative;
  bottom: 30rem;
  color: #ffff;
  font-weight: 900;
}

/* defender css */
.navbar-container-new {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 2px 5px #e9ecec;
  position: sticky;
  align-items: center;
  top: 0;
  background-color: #fff;
  z-index: 100;
  padding: 0 24px 0 0;
}
.nav-logo-new {
  font-size: 15px;
  font-family: "JaguarExtraBold";
  margin: 0px 0px 0px 0px;
  cursor: pointer;
}
