.modal_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 65%;
  background-color: #fff;
  padding: 20px;
}
.description_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: #fff;
  padding: 20px;
  z-index: 1;
}
.stepper_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 330px;
  background-color: #fff;
  padding: 20px;
  z-index: 1;
}
.buynow_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 585px;
  background-color: #fff;
  padding: 20px;
  z-index: 1;
}
.terms_container {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  overflow-x: auto;
  height: 585px;
  background-color: #fff;
  padding: 20px 20px;
  z-index: 999;
}
.vehicle_list_data {
  display: flex;
  align-items: center;
  gap: 5px;
}
.check_button {
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #005a2b;
  margin-top: 0px;
}
.detail_button {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  width: 13rem !important;
  height: 3rem !important;
  margin-top: 6px;
}
.feature_content {
  height: 450px;
  overflow-y: auto;
}
.buynow_button {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

@media only screen and (max-width: 600px) {
  .modal_container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    height: 400px;
    overflow-y: auto;
    background-color: #fff;
    padding: 30px;
  }
  .description_container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    /* height: 400px; */
    overflow-y: auto;
    background-color: #fff;
    padding: 30px;
  }
  .stepper_container {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    overflow-x: auto;
    height: 400px;
    background-color: #fff;
    padding: 20px 0;
    z-index: 1;
  }
  .buynow_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    overflow-x: auto;
    height: 585px;
    background-color: #fff;
    padding: 20px 0;
    z-index: 1;
  }

  .buynow_button {
    flex-direction: column;
    align-items: center;
  }
  .container_padding {
    padding: 15px;
  }
  .steper_container {
    display: none !important;
  }
}
